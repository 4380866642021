.wiki-main-buttons, .wiki-last-buttons {
  display: table-cell;
}

.wiki-buttons-invisible {
  display: none;
}

.u-tcol-6.back-buttons-invisible {
  display: none;
}

.upper-roll-menu-visible {
  display: block;
}

p.modal-input-error {
  display: none;
}